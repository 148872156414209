<template>
    <div class="head">
        <div class="title" @click="goPage('')">
          <img class="logo" src="@/assets/img/logo.png"/>
          <img class="name" src="@/assets/img/logo.svg"/>
        </div>
        <div class="menu" v-if="route.name !== 'home'">
          <div @click="goPage('app')" :class="route.name==='app'?'active item':'item'">Lend</div>
          <div @click="goPage('rwa')" :class="route.name==='rwa'?'active item':'item'">RWA</div>
          <div class="item disable">Points</div>
          <div :class="route.name==='profit'?'active item disable':'item disable'">Profit</div>
          <div @click="goPage('activity')" :class="route.name==='activity'?'active item':'item'">OG Role Event<img src="@/assets/img/fire.svg"/></div>
        </div>
        <div class="lv" v-if="route.name !== 'home'" @click="goPage('activity')">
          <img v-if="ogScoreLv === 1" src="@/assets/img/001.png"/>
          <img v-if="ogScoreLv === 2" src="@/assets/img/002.png"/>
          <img v-if="ogScoreLv === 3" src="@/assets/img/003.png"/>
        </div>
        <div class="wallet" v-if="route.name !== 'home'"><wallet-multi-button :featured="4"></wallet-multi-button></div>
        <div class="app" v-if="route.name === 'home'" @click="goPage('app')">Launch App</div>
    </div>
    <LoadingView v-if="isLoadingFlag" />
</template>
<script setup>
/* eslint-disable */
import { WalletMultiButton, useWallet, useAnchorWallet } from 'solana-wallets-vue'
import { useRoute, useRouter, RouteRecordRaw } from 'vue-router'
import { ref,computed,watch,onMounted,onUnmounted } from 'vue'
import { reserve_list,OG_LV2_SCORE,OG_LV3_SCORE,OG_LV1_SCORE } from '../lib/value'
import LoadingView from './LoadingView.vue'
import * as nxlendfunc from '../lib/index.ts'
import {getOgscore} from '@/lib/online'
import appStore from '@/store'
const route = useRoute()
const router = useRouter()
const isLoadingFlag = ref(false)
const { publicKey,wallet } = useWallet()
const timeOut = ref(null)
const getBalanceCounts = ref(0)
watch(() => publicKey, () => {
  if(publicKey.value) {
    getOgscore(publicKey.value)
  } else {
    appStore.ogscore.state.ogscore = 0
  }
  getBalanceCounts.value = 0
  console.log('wallet.value?.adapter',wallet.value?.adapter)
  init(true)
}, {
  deep: true
})
watch(() => route.path, () => {
  console.log('watch route',route)
  if(route.name.indexOf('rwa') > -1 || route.name.indexOf('home') > -1 ) {
    isLoadingFlag.value = false
  } else {
    isLoadingFlag.value = true
    init()  
  }
}, {
  deep: true
})
watch(() => appStore.account.state.reserveChange, () => {
  isLoadingFlag.value = false
}, {
  deep: true
})
const ogScoreLv = computed(()=> {
  const score = appStore.ogscore.state.ogscore
  let lv = 0
  if(score >= OG_LV1_SCORE) {
    lv = 1
  }
  if(score >= OG_LV2_SCORE) {
    lv = 2
  }
  if(score >= OG_LV3_SCORE) {
    lv = 3
  }
  return lv
})
const toApp=() => {
  window.open("https://alpha.nxfi.io/")
}
const init = ($clear = false) => {
  console.log('route',route.name)
  const pageName = route.name
  if (pageName === 'home' || pageName === 'rwa') {
    return
  }
  if (timeOut.value) clearTimeout(timeOut.value);
  timeOut.value = setTimeout(() => {
    timeOut.value = null;
    if(getBalanceCounts.value < 1) {
      getBalanceCounts.value = getBalanceCounts.value + 1
      nxlendfunc.getReserveListOwner(true,$clear)
    } else {
      getBalanceCounts.value = 0
      nxlendfunc.getReserveListOwner(false,$clear)
    }
  }, 500);
}
const setTime = ref(0)
const start = () => {
  setTime.value = setInterval(()=>{
    init()
  },30000)
}

const stop = () => {
  clearInterval(setTime.value);
}
onMounted(async() => {
  if(route.name.indexOf('rwa') > -1 || route.name.indexOf('home') > -1 ) {
    isLoadingFlag.value = false
  } else {
    isLoadingFlag.value = true
  }
  init()
  if(publicKey.value) {
    getOgscore(publicKey.value)
  }
  setTimeout(() => {
    start()
  }, 30000);
})
onUnmounted(() => {
    stop();
});
const goPage = ($path) => {
  router.push({
    path: `/${$path}`
  })
}
</script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.head{
  height: 64px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background-color: #0F1112;
  //border-bottom:1px solid #212121;
  align-items: center;
  z-index:2;
  .title {
    cursor: pointer;
    width: 194px;
    display: flex;
    flex-direction: row;
    align-items: center;
    .logo {
      height:55px;
      margin:7px 0 8px 10px;
    }
    .name {
      height:28px;
      margin:7px 0 8px 10px;
    }
    span {
      color: #C958FF;
      font-family: Orbitron, sans-serif;
      font-size: 26px;
      font-style: normal;
      font-weight: 900;
      line-height: 100%; /* 26px */
      text-transform: capitalize;
      transform: translate(-6px, 10px);
    }
  }
  .menu {
    flex: 1;
    display: flex;
    flex-direction: row;
    justify-content: center;
    color: #fff;
    .disable {
      opacity: 0.4;
    }
    .item{
      cursor: pointer;
      font-size: 18px;
      font-weight: 600;
      margin: 0 28px;
      border-bottom: 3px solid rgba(0,0,0,0);
      padding: 23px 0 20px;
      display: flex;
      align-items: center;
      img {
        margin-left: 5px;
      }
    }
    .active{
      border-bottom: 3px solid #C958FF;
      transition: all ease 0.3s;
    }
  }
  .lv {
    width: 40px;
    display: flex;
    align-items:center;
    justify-content:center;
    img{
      width:30px;
    }
  }
  .wallet{
    width: 194px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding-right: 20px;
    box-sizing: border-box;
    div {
      .swv-dropdown {
        display: flex;
        width: 138px;
        height: 38px;
        padding: 10px 15px;
        justify-content: center;
        align-items: center;
        .swv-button-trigger{
          background-color: none !important;
        }
      }
    }
  }
  .app {
    margin-right: 40px;
    cursor: pointer;
    display: flex;
    width: 131px;
    height: 32px;
    padding: 5px;
    justify-content: center;
    align-items: center;
    gap: 5px;
    flex-shrink: 0;
    border-radius: 6px;
    background: linear-gradient(90deg, #C958FF 0%, #7E57EF 100%);
    color: #FFF;
    font-family: Inter,sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    box-sizing: border-box;
    z-index: 1;
  }
}
@media (min-width:800px) and (max-width: 1050px) {
.head{
  height: 64px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background-color: #0F1112;
  //border-bottom:1px solid #212121;
  align-items: center;
  position: relative;
  z-index:2;
  .title {
    cursor: pointer;
    width: 174px;
    display: flex;
    flex-direction: row;
    align-items: center;
    .logo {
      height:55px;
      margin:7px 0 8px 10px;
    }
    .name {
      height:28px;
      margin:7px 0 8px 10px;
    }
    span {
      color: #C958FF;
      font-family: Orbitron, sans-serif;
      font-size: 26px;
      font-style: normal;
      font-weight: 900;
      line-height: 100%; /* 26px */
      text-transform: capitalize;
      transform: translate(-6px, 10px);
    }
  }
  .menu {
    flex: 1;
    display: flex;
    flex-direction: row;
    justify-content: center;
    color: #fff;
    .disable {
      opacity: 0.4;
    }
    .item{
      cursor: pointer;
      font-size: 16px;
      font-weight: 600;
      margin: 0 12px;
      border-bottom: 3px solid rgba(0,0,0,0);
      padding: 23px 0 20px;
    }
    .active{
      border-bottom: 3px solid #C958FF;
      transition: all ease 0.3s;
    }
  }
  .lv {
    width: 40px;
    display: flex;
    align-items:center;
    justify-content:center;
    img{
      width:30px;
    }
  }
  .wallet{
    width: 174px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding-right: 20px;
    box-sizing: border-box;
    div {
      .swv-dropdown {
        display: flex;
        width: 138px;
        height: 38px;
        padding: 10px 15px;
        justify-content: center;
        align-items: center;
        .swv-button-trigger{
          background-color: none !important;
        }
      }
    }
  }
  .app {
    margin-right: 40px;
    cursor: pointer;
    display: flex;
    width: 131px;
    height: 32px;
    padding: 5px;
    justify-content: center;
    align-items: center;
    gap: 5px;
    flex-shrink: 0;
    border-radius: 6px;
    background: linear-gradient(90deg, #C958FF 0%, #7E57EF 100%);
    color: #FFF;
    font-family: Inter,sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    box-sizing: border-box;
    z-index: 1;
  }
}
}
@media (max-width:800px) {
  .head {
    .title {
      .logo {
        height:44px;
      }
      .name {
        height: 23px;
        margin-left: 6px;
      }
    }
    .menu {
      display: none !important;
    }
    .app {
      margin-right: 20px !important;
    }
    .lv {
      flex:1;
      justify-content: end;
    }
  }
}
</style>
  