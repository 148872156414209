import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '@/views/HomeView/index.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/app',
    name: 'app',
    component: () => import(/* webpackChunkName: "about" */ '../views/AppView/index.vue')
  },
  {
    path: '/rwa',
    name: 'rwa',
    component: () => import(/* webpackChunkName: "about" */ '../views/RwaView/index.vue')
  },
  {
    path: '/profit',
    name: 'profit',
    component: () => import(/* webpackChunkName: "about" */ '../views/ProfitView/index.vue')
  },
  {
    path: '/activity',
    name: 'activity',
    component: () => import(/* webpackChunkName: "about" */ '../views/ActivityView/index.vue')
  },
  // {
  //   path: '/liqlist',
  //   name: 'liqlist',
  //   component: () => import(/* webpackChunkName: "about" */ '../views/LiqList/index.vue')
  // },
  { path: '/:catchAll(.*)', redirect: '/app' }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
