<template>
  <HeadView />
  <router-view/>
  <FootView />
  <MobileView />
</template>
<script setup>
/* eslint-disable */
import HeadView from '@/components/HeadView.vue'
import FootView from '@/components/FootView.vue'
import MobileView from '@/components/MobileView.vue'
import { ref, watch, onMounted,computed } from 'vue'
import { useRoute, useRouter, RouteRecordRaw } from 'vue-router'
import { useWallet } from 'solana-wallets-vue'
import { registe } from '@/lib/online'
import { delay } from '@/lib'
const route = useRoute()
const { publicKey } = useWallet()
onMounted(async() => {
  if(route.query.channel) {
    window.localStorage.setItem('og_channel',route.query.channel)
  }
  if(route.query.referrercode) {
    window.localStorage.setItem('og_referrercode',route.query.referrercode)
  }
  if(window.solana?.isONTO && window.onto) {
    window.localStorage.setItem('og_channel',21)
    const connectedWallet = window.localStorage.getItem('walletName')
    if(!(connectedWallet === 'Phantom' || connectedWallet === 'ONTO')) {
      window.localStorage.removeItem('walletName')
    }
  }
})
watch(() => publicKey.value, async () => {
  console.log('connect wallet')
  await delay(2000)
  const og_channel = window.localStorage.getItem('og_channel')
  const og_referrercode = window.localStorage.getItem('og_referrercode')
  if(publicKey.value && (og_channel || og_referrercode)){
    await registe(publicKey.value.toBase58(),og_referrercode,og_channel)
  }
}, {
  deep: true
})
// export default {
//     name: 'aPPView',
//     components: {
//       HeadView,
//       FootView,
//       MobileView
//     }
//   }
</script>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  background-color: #0F1112;
}
.up{
  color:#6AFFFF !important;
}
.down{
  color:#FF6A6A !important;
}
input::-webkit-inner-spin-button,
input::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
input[type="number"] {
    -moz-appearance: textfield;
}

@font-face {
  font-family: 'ibm1';
  src: url('@/assets/font/ibm_light.woff2') format('woff2');
}
@font-face {
  font-family: 'ibm2';
  src: url('@/assets/font/ibm_regular.woff2') format('woff2');
}
@font-face {
  font-family: 'ibm3';
  src: url('@/assets/font/ibm_blod.woff2') format('woff2');
}
@font-face {
  font-family: 'ibm4';
  src: url('@/assets/font/ibm_extraBlod.woff2') format('woff2');
}
@font-face {
  font-family: 'Poppins'; //字体名字
  src: url('@/assets/font/poppins/Poppins-Regular.ttf'); //引入字体位置
}
@font-face {
  font-family: 'Poppins Blod'; //字体名字
  src: url('@/assets/font/poppins/Poppins-Bold.ttf'); //引入字体位置
}
@font-face {
  font-family: 'Poppins Medium'; //字体名字
  src: url('@/assets/font/poppins/Poppins-Medium.ttf'); //引入字体位置
}
body{
  margin: 0;
}
nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
/* 修改整体轨道的宽度 */
::-webkit-scrollbar {
    width: 0px; /* 根据需要调整大小 */
}
 
/* 修改滑块的样式 */
::-webkit-scrollbar-thumb {
    background-color: #3A3A3A; /* 根据需要调整颜色 */
    border-radius: 0px; /* 根据需要调整边角形状 */
}
 
/* 当滑块处于活动状态时的样式 */
::-webkit-scrollbar-thumb:hover {
    background-color: #666; /* 根据需要调整颜色 */
}
 
/* 添加按钮（向左/向右）的样式 */
::-webkit-scrollbar-button {
    display: none; /* 不显示按钮 */
}
</style>
<style>
.el-scrollbar {
    --el-scrollbar-opacity: 0 !important;
    --el-scrollbar-bg-color: #151617 !important;
    --el-scrollbar-hover-opacity: 0 !important;
    --el-scrollbar-hover-bg-color: #4f3776 !important;
}
.el-notification {
  border-radius: 11px !important;
  background: #151617 !important;
  border: 1px solid #151617 !important;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.50);
}
.el-notification__title{
  color: #fff !important;
  background-color: #151617 !important;
  font-family: 'Poppins',sans-serif !important;
  font-size: 20px !important;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.el-notification__content {
  color: #fff !important;
  background-color: #151617 !important;
  color: #A1A1A1!important;
  font-family: 'Poppins',sans-serif !important;
  font-size: 14px !important;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.el-switch__core {
  background-color: #3F3E40 !important;
  border: 1px solid #3F3E40 !important;
}
.el-switch.is-checked .el-switch__core {
  background-color: #7340C9 !important;
}
.el-popper__arrow {
  display: none !important;
}
.el-popper {
  padding: 15px !important;
  border-radius: 11px !important;
  background-color: #151617 !important;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.50) !important;
  border: none !important;
}
.el-dropdown-menu {
  background-color: #151617 !important;
}
.el-dropdown-menu__item {
  color: #FFF !important;
  text-align: center !important;
  font-family: "Poppins",sans-serif !important;
  font-size: 14px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: normal !important;
  border-radius: 11px !important;
}
.el-dropdown-menu__item:hover {
  color: #FFF !important;
  text-align: center !important;
  font-family: "Poppins",sans-serif !important;
  font-size: 14px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: normal !important;
  border-radius: 11px !important;
  background: #4F3776 !important;
}
.el-popper.is-light {
  background-color: #151617 !important;
}
.head .wallet div .swv-dropdown {
  display: flex;
  width: 138px;
  height: 38px;
  padding: 10px 15px;
  justify-content: center;
  align-items: center;
  border-radius:10px;
}
.head .wallet div .swv-dropdown .swv-button {
  display: flex;
  width: 145px;
  height: 38px;
  padding: 10px 15px;
  justify-content: center;
  align-items: center;
  border-radius:10px;
}
.head .wallet div .swv-dropdown .swv-button p {
  color: #FFF;
  text-align: center;
  font-family: "Poppins",sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: capitalize;
}
.head .wallet div .swv-dropdown .swv-button-trigger {
  background-color: #212121;
}
.head .wallet div .swv-button-trigger {
  background-color: #212121;
  font-family: "Poppins",sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  width: 138px;
  height: 38px;
  padding: 10px 15px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.swv-button > p {
  margin-left: 10px !important;
  overflow: hidden !important;
  white-space: nowrap !important;
}
.head .wallet div .swv-dropdown .swv-button > * + * {
    margin-left: 10px;
    overflow: hidden;
    white-space: nowrap;
}
.head .wallet div .swv-dropdown .swv-dropdown-list {
  background-color: #212121;
}
.head .wallet div .swv-dropdown .swv-dropdown-list .swv-dropdown-list-item {
  font-size: 14px;
  color: #fff;
  font-family: "Poppins",sans-serif;
}
.head .wallet div .swv-dropdown .swv-dropdown-list .swv-dropdown-list-item:not([disabled]):hover {
  font-size: 14px;
  color: #fff;
  font-family: "Poppins",sans-serif;
  background-color: #353535;
}
.swv-modal-wrapper {
  background-color: #151617 !important;
}
.swv-modal-title {
  color: #FFF;
  text-align: center !important;
  font-family: "Poppins",sans-serif !important;
  font-size: 24px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: normal !important;
}
.swv-button {
  color: #FFF !important;
  text-align: center !important;
  font-family: "Poppins",sans-serif !important;
  font-size: 14px !important;
  font-style: normal !important;
  font-weight: 500;
  line-height: normal;
  text-transform: capitalize;
}
.swv-modal-wrapper .swv-button:not([disabled]):hover {
  background-color: #212125 !important;
}
.swv-modal-button-close {
  background-color: #151617 !important;
  color: #fff !important;
}
.swv-modal-button-close:hover {
  color: #fff !important;
}
.swv-modal-overlay {
  background-color: rgba(0,0,0,0.7) !important;
}
.el-dialog__headerbtn {
  width:  34px !important;
  height: 34px !important;
  border-radius:17px;
  background-color: #151617 !important;
  top: 34px !important;
  right: 17px !important;
}
.el-dialog {
  background-color: transparent !important;
  max-width: 100%;
  box-sizing: border-box;
}
::v-deep .el-form-item {
  margin: 0 5px !important;
}
::v-deep .el-table,
.el-table__expanded-cell {
  padding: 20px !important;
  background-color: #0F1112 !important;
}
.el-table th {
  background-color: transparent !important;
  border-bottom-color: rgba(255,255,255,0) !important;
  color: #747474;
  font-family: 'Poppins', sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400 !important;
  line-height: normal;
}
.profit .el-table th {
  background-color: #0F1112 !important;
  border-bottom-color: rgba(255,255,255,0) !important;
  color: #747474;
  font-family: 'Poppins', sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400 !important;
  line-height: normal;
}
.market .el-table th {
  background-color: #0F1112 !important;
  border-bottom-color: rgba(255,255,255,0) !important;
  color: #747474;
  font-family: 'Poppins', sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400 !important;
  line-height: normal;
}
.el-table th:hover {
  /* background-color: #0F1112 !important; */
  border-bottom-color: rgba(255,255,255,0) !important;
  color: #747474;
  font-family: 'Poppins', sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400 !important;
  line-height: normal;
}
::v-deep .el-table tr {
  font-family: 'Poppins', sans-serif;
  background-color: transparent !important;
}
.el-table tr {
  font-family: 'Poppins', sans-serif;
  background-color: transparent !important;
}
.el-table tr:hover {
  background-color: transparent !important;
}
.el-table--enable-row-hover .el-table__body tr:hover>td.el-table__cell {
    background-color: #0F1112 !important;
}
::v-deep .el-table--enable-row-transition .el-table__body td,
::v-deep .el-table .cell {
  font-family: 'Poppins', sans-serif;
  background-color: transparent !important;
  color: rgba(255, 255, 255, 0.7) !important;
  border: none !important;
  padding-left: 0 !important;
  padding-right: 0 !important;
}
::v-deep .el-table th > .cell {
  font-family: 'Poppins', sans-serif;
  color: rgba(254, 254, 254, 1) !important;
  font-weight: 700 !important;
}
.el-table::before {
  font-family: 'Poppins', sans-serif;
  left: 0 !important;
  bottom: 0 !important;
  width: 100%!important;
  height: 0px !important;
}
 
::v-deep .el-form-item__label {
  color: white !important;
}
.el-table td.el-table__cell {
    border-bottom: none !important; 
    box-shadow: 0px -1px 0px 0px rgba(255, 255, 255, 0) inset;
}
.el-table .cell {
  padding-right: 0 !important;
}
.el-table th.el-table__cell {
  text-align: right;
  padding-right: 0 !important;
}
.market .el-table__body tr.hover-row>td.el-table__cell {
  background-color: #0F1112 !important;
}
.profit .el-table__body tr.hover-row>td.el-table__cell {
  background-color: #0F1112 !important;
}
.dashboard .el-table__body tr.hover-row>td.el-table__cell {
  background-color: transparent !important;
}
.el-table th.el-table__cell:first-child {
  text-align: left;
}
.el-table td.el-table__cell {
  text-align: right;
}
.profit .el-table th.el-table__cell:first-child {
  text-align: left !important;
}
.profit .el-table th.el-table__cell:last-child {
  text-align: right !important;
}
.profit .el-table th.el-table__cell {
  text-align: center ;
}
.profit .el-table td.el-table__cell {
  text-align: center !important;
}
.profit .el-table td.el-table__cell:first-child {
  text-align: left !important;
}
.profit .el-table td.el-table__cell:last-child {
  text-align: right !important;
}
.el-table td.el-table__cell:first-child {
  text-align: left;
}
.el-table td.el-table__cell div {
  color: #FFF;
  text-align: center;
  font-family: 'Poppins', sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-align: right;
  padding-right: 0 !important;
}
.dashboard .el-table--enable-row-transition .el-table__body td.el-table__cell {
  height: 78px;
  box-sizing: border-box;
  background-color: transparent !important;
}
.market .el-table--enable-row-transition .el-table__body td.el-table__cell {
  height: 78px;
  box-sizing: border-box;
  background-color: #0F1112 !important;
}
.market .el-table--enable-row-transition .el-table__body th.el-table__cell {
  background-color: #0F1112 !important;
}
.profit .el-table--enable-row-transition .el-table__body td.el-table__cell {
  height: 78px;
  box-sizing: border-box;
  background-color: #0F1112 !important;
}
.profit .el-table--enable-row-transition .el-table__body th.el-table__cell {
  background-color: #0F1112 !important;
}
.el-table {
  border-bottom: none !important;
  border-bottom-color: #0F1112 !important;
}
div ::v-deep .el-table--border::after,
div ::v-deep .el-table--group::after,
div ::v-deep .el-table::before {
    background-color: transparent;
}
.el-loading-mask {
  background-color: rgba(0,0,0,0.5) !important;
}
.el-loading-spinner .path {
  stroke: #c958ff  !important;
}
.el-slider__bar {
  background-color: #7340C9 !important;
}
.el-slider__button {
  border: solid 2px #7340C9 !important;
}
.el-checkbox__inner:hover {
    border-color: #7340C9 !important;
}
.el-checkbox__input.is-checked .el-checkbox__inner {
  background-color: #7340C9 !important;
  border-color: #7340C9 !important;
}
.el-checkbox__input.is-checked+.el-checkbox__label {
  color: #FFF !important;
  font-family: 'Poppins',sans-serif;
  font-size: 14px !important;
  font-style: normal;
  font-weight: 400 !important;
  line-height: normal;
}
.el-checkbox__inner {
  background-color: rgba(0,0,0,0) !important;
  border: 2px solid #717171 !important;
}
.el-checkbox__label {
  color: rgba(255,255,255,0.6) !important;
  font-family: 'Poppins',sans-serif;
  font-size: 14px !important;
  font-style: normal;
  font-weight: 400 !important;
  line-height: normal;
}
.el-radio-button .el-radio-button__inner {
    background-color: #2f2f2f;
  }
  .el-radio-button__inner {
    background-color: #2f2f2f;
    color: #fff;
    border: none !important;
  }
  .el-radio-button--large .el-radio-button__inner {
    background-color: #2f2f2f;
    color: #fff;
    border: rgba(0,0,0,0.8);
  }
  .act  .el-progress-bar__outer {
            background-color: #000 !important;
          }
@media (max-width: 800px) {
  .set .el-checkbox__label{
    font-size: 12px;
  }
}
</style>
